import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; //@3.4.2
import { MatDialogModule } from '@angular/material/dialog';
import { MatModalInfoDialogComponent } from './components/modais/mat-modal-info-dialog/mat-modal-info-dialog.component';
import { ModulosResolver } from './resolvers/modulos.resolver';
import { ModuloService } from './services/modulo.service'; 
import { DataService } from './services/data.service';
import { GtagService } from './services/gtag.service';
import { HeaderComponent } from './components/partial/header/header.component';
import { FooterComponent } from './components/partial/footer/footer.component';
import { BtnListaDesejoComponent } from './components/btns/btn-lista-desejo/btn-lista-desejo.component';
import { BtnCartComponent } from './components/btns/btn-cart/btn-cart.component';
import { MenuComponent } from './components/partial/menu/menu.component';
import { ServicoService } from './services/servico.service';
import { StorageService } from './services/storage.service';
import { ValidatorService } from './services/validator.service';
import { DateService } from './services/date.service';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoaderModule } from './modules/loader/loader/loader.module';
import { ProdutoPartialModule } from './modules/produto/produto-partial/produto-partial.module';
import { ModuloModule } from './modules/modulo/modulo.module';
import "@angular/compiler";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BtnUsuarioLoginModule } from './modules/btn/btn-usuario-login/btn-usuario-login.module';
import { FormSearchComponent } from './components/partial/form-search/form-search.component';
import { LgListComponent } from './components/partial/lg-list/lg-list.component';
import { LogoFooterComponent } from './components/partial/logo-footer/logo-footer.component';
import { ContatoFooterComponent } from './components/partial/contato-footer/contato-footer.component';
import { PipeModule } from './modules/pipe/pipe.module';
import { MenuActionPartialComponent } from './components/partial/menu-action-partial/menu-action-partial.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { SidebarMenuComponent } from './components/partial/sidebar-menu/sidebar-menu.component';
import { MenuPartialComponent } from './components/partial/menu-partial/menu-partial.component';
import { HotelPageComponent } from './pages/cadastro/hotel/hotel-page/hotel-page.component';
import { PasseioModalComponent } from './components/evento/passeio-modal/passeio-modal.component';

registerLocaleData(localePt,'pt-BR');     

const chaveFacebook = "4795923320512047";      
const chaveGoogle   = "560446039749-8hh5n321daspflsghhegltire9d99nab.apps.googleusercontent.com";  

const fbLoginOptions = {
  scope: 'email',  
  return_scopes: true,  
  enable_profile_selector: true,
  version: "v16.0",
  xfbml: true
};

@NgModule({ 
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        SidebarMenuComponent,
        MenuComponent,
        BtnCartComponent,
        BtnListaDesejoComponent,
        MatModalInfoDialogComponent,
        FormSearchComponent,
        LgListComponent,
        LogoFooterComponent,
        ContatoFooterComponent,
        MenuActionPartialComponent,
        MenuPartialComponent,
        HotelPageComponent
    ],
    exports: [
      SidebarMenuComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
      MenuActionPartialComponent
    ],
    imports: [
        BtnUsuarioLoginModule,
        ModuloModule,
        LoaderModule,
        ProdutoPartialModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        RecaptchaV3Module,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        PipeModule,
        SocialLoginModule 
    ],
    providers: [
        DataService,
        ModuloService,
        ModulosResolver,  
        GtagService,
        ServicoService,
        StorageService,
        ValidatorService,  
        DateService,  
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ldhw-ogAAAAADsij8PWzSXRrpaHvCctjnvDqbnp' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(
                    chaveGoogle,
                    {
                      scope: 'profile email',
                      plugin_name:'sample_login' 
                    }
                  )
                },{
                  id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider(
                    chaveFacebook,
                    fbLoginOptions
                  )
                }
              ]
            } as SocialAuthServiceConfig,
          }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
