import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'eventoDateFormat'
})
export class EventoDateFormatPipe implements PipeTransform {

  constructor(
    private DateService: DateService
  ){

  }

  transform(evento: any, args?: any): any {
    
    let data_inicio  = evento.data_inicio_view;
    let data_fim    = evento.data_fim_view;
    let month_inicial = this.DateService.getMonth(data_inicio);
    let month_final   = this.DateService.getMonth(data_fim);
    let year_inicial  = this.DateService.getYear(data_inicio);
    let year_final    = this.DateService.getYear(data_fim);
    let day_inicial   = this.DateService.getDay(data_inicio);
    let day_final     = this.DateService.getDay(data_fim);
    let month_inicial_str = this.DateService.getMonthString(data_inicio);

    if(data_inicio == data_fim){
      return day_inicial+" de "+this.DateService.getMonthString(data_inicio)+" de "+year_inicial;
    }else if((month_inicial == month_final) && (year_inicial == year_final)){
      return day_inicial+" a "+day_final+" de "+month_inicial_str+" de "+year_inicial;
    }else if(year_inicial == year_final){
      return day_inicial+"/"+month_inicial+" a "+day_final+"/"+month_final+" de "+year_inicial;
    }else{
      return day_inicial+"/"+month_inicial+" de "+year_inicial+" a "+day_final+"/"+month_final+" de "+year_final;
    }

  }

}
